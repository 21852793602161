<!-- 客源分析 -->
<template>
  <section class="cont analysisDaily">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <el-row class="table-box">
        <!-- 表格按钮 -->
        <div class="flex">
          <div>
            <label class="m-right-15">营业时间</label>
            <el-date-picker v-model="date" type="date" value-format="yyyy-MM-dd" :picker-options="pickerOptions"
             placeholder="选择日期" :clearable="false" @change="getData"></el-date-picker>
          </div>
          <div class="title">客源分析日报</div>
          <div>
            <el-button type="primary" @click="exportSourceAnalysis"><span>导出明细</span></el-button>
          </div>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border fit stripe :header-cell-style="{ textAlign: 'center' }">
          <el-table-column prop="channelName" label="渠道" align="center"></el-table-column>
          <el-table-column prop="bookNum" label="预定数量" align="center"></el-table-column>
          <el-table-column label="百分比" align="center">
            <template slot-scope="scope">{{ scope.row.percent }}%</template>
          </el-table-column>
          <el-table-column label="房费" align="center">
            <template slot-scope="scope">￥{{ scope.row.fees }}</template>
          </el-table-column>
          <el-table-column label="平均房价" align="center">
            <template slot-scope="scope">￥{{ scope.row.avgRoomFees }}</template>
          </el-table-column>
          <el-table-column prop="bookNumTotal" label="渠道数量累计" align="center"></el-table-column>
          <el-table-column label="累计房费" align="center">
            <template slot-scope="scope">￥{{ scope.row.feesTotal }}</template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-row>
  </section>
</template>

<script>
  import { getSourceAnalysis, exportSourceAnalysis } from '@/api/pms/statistics/businessDaily'
  import { dateFactory, exportFile } from '@/common/js/common'
  import { mapState } from 'vuex'
  export default {
    name: 'analysisDaily',
    data() {
      return {
        crumbs: new Map([['PMS'], ['统计中心'], ['客源分析']]),
        date: dateFactory.dateFormat(false),
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() >= Date.now()
          },
        },
        tableData: [],
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
    },
    mounted() {
      this.getData()
    },
    methods: {
      getData() {
        let data = {
          hotelId: this.hotelInfo.id,
          beginDateStr: this.date,
          endDateStr: this.date,
        }
        getSourceAnalysis(data).then(({ records, success }) => {
          if (success) {
            this.tableData = records
          }
        })
      },
      // 报表导出
      exportSourceAnalysis() {
        let data = {
          hotelId: this.hotelInfo.id,
          beginDateStr: this.date,
          endDateStr: this.date,
        }
        exportSourceAnalysis(data).then((res) => {
          exportFile(res, '客源分析日报')
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .analysisDaily {
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .title {
        margin-left: -270px;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
</style>
